import { useEffect, useState } from 'react'
import { ENDPOINTS, axiosInstance } from '../api'
import { getExtension } from '../utils/get-extension'
import { ERROR_MAP } from '../constants'

export const useQueue = ({ files, captchaSolved, updateFile }) => {
    const [queueBlocked, setQueueBlocked] = useState(false)
    const [fileQueue, setFileQueue] = useState([])
    const [clientSessionId, setClientSessionId] = useState(null)

    const upload = async (file) => {
        if (!captchaSolved) return

        try {
            setQueueBlocked(true)

            let session = clientSessionId

            if (!session) {
                const { data } = await axiosInstance.get(
                    ENDPOINTS.CREATE_SESSION
                )
                session = data.clientId
                setClientSessionId(data.clientId)
            }

            const formData = new FormData()
            formData.append('videoFile', file.file)
            formData.append('size', file.file.size)
            formData.append('length', Math.ceil(file.duration / 60))
            formData.append('format', getExtension(file.file))
            formData.append('clientId', session)

            const { data } = await axiosInstance.post(
                ENDPOINTS.UPLOAD,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: ({ loaded, total, ...rest }) => {
                        const percentageUploaded = Math.floor(
                            (loaded / total) * 100
                        )

                        const progress = Math.min(percentageUploaded, 99)

                        updateFile({ tempId: file.tempId, progress })
                    },
                }
            )

            updateFile({
                ...file,
                uploaded: true,
                progress: 100,
                fileId: data.fileId,
            })
        } catch (err) {
            updateFile({
                ...file,
                progress: 0,
                error:
                    ERROR_MAP[err?.errorStatus] ||
                    'Wgrywanie pliku nie powiodło się.',
            })
        } finally {
            setFileQueue((prev) =>
                prev.filter((queuedFile) => queuedFile.tempId !== file.tempId)
            )
            setQueueBlocked(false)
        }
    }

    useEffect(() => {
        if (fileQueue.length > 0 && !queueBlocked) {
            upload(fileQueue[0])
        }
    }, [fileQueue, queueBlocked])

    useEffect(() => {
        const newFiles = files.filter((file) => {
            const durationCorrect = !!file.duration

            const fileReadyForUpload =
                file.analysisReady && durationCorrect && !file.error
            const alreadyAddedToQueue =
                fileQueue.some(
                    (queuedFile) => queuedFile.tempId === file.tempId
                ) || file.uploaded

            return fileReadyForUpload && !alreadyAddedToQueue
        })

        if (newFiles.length > 0) {
            setFileQueue((queue) => [...queue, ...newFiles])
        }
    }, [files])

    const removeFileFromQueue = (tempId) => {
        setFileQueue((queue) => queue.filter((file) => file.tempId !== tempId))
    }

    return {
        clientSessionId,
        removeFileFromQueue,
    }
}
