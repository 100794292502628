import React, { createContext, useState, useContext, useMemo } from 'react'
import { DEFAULT_VALUES, FORM_FIELDS, STEPS, formSchema } from '../constants'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { calculatePrice } from '../utils/calculate-price'
import { ENDPOINTS, axiosInstance } from '../api'
import { formatSubmitData } from '../utils/format-submit-data'
import { PaymentOverlay } from './payment-overlay'
import { useQueue } from '../hooks/use-upload-queue'

const WizardContext = createContext('')

export const WizardContextProvider = ({ children }) => {
    const [activeStep, setActiveStep] = useState(STEPS[0])
    const [stepError, setStepError] = useState(null)
    const [summaryExpanded, setSummaryExpanded] = useState(false)
    const [captchaSolved, setCaptchaSolved] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState(null)
    const [transactionPaymentUrl, setTransactionPaymentUrl] = useState(null)

    const methods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: DEFAULT_VALUES,
    })

    const selectedFiles = methods.watch(FORM_FIELDS.files)
    const completionTime = methods.watch(FORM_FIELDS.completionTime)

    const totalDuration = useMemo(() => {
        return (selectedFiles ?? [])
            .map((file) => Math.ceil(file.duration / 60))
            .reduce((a, b) => a + b, 0)
    }, [selectedFiles])

    const unverifiedFiles = useMemo(() => {
        return selectedFiles.filter(
            (file) => !file.duration && file.analysisReady
        )
    }, [selectedFiles])

    const price = useMemo(() => {
        return calculatePrice(totalDuration, completionTime)
    }, [totalDuration, completionTime])

    const uploadInProgress = (selectedFiles ?? []).some(
        (file) => !file.error && file.duration && file.progress < 100
    )
    const analysisInProgress = (selectedFiles ?? []).some(
        (file) => !file.analysisReady
    )

    const updateFile = (value) => {
        const selectedFiles = methods.getValues(FORM_FIELDS.files)

        methods.setValue(
            FORM_FIELDS.files,
            selectedFiles.map((file) => {
                if (file.tempId === value.tempId) return { ...file, ...value }
                return file
            })
        )
    }

    const { removeFileFromQueue, clientSessionId } = useQueue({
        files: selectedFiles,
        captchaSolved,
        updateFile,
    })

    const deleteFile = async (fileToDelete) => {
        try {
            removeFileFromQueue(fileToDelete.tempId)

            if (fileToDelete.fileId) {
                await axiosInstance.post(ENDPOINTS.DELETE_FILE, {
                    fileId: fileToDelete.fileId,
                    clientId: clientSessionId,
                })
            }

            const selectedFiles = methods.getValues(FORM_FIELDS.files)

            methods.setValue(
                FORM_FIELDS.files,
                selectedFiles.filter(
                    (file) => file.tempId !== fileToDelete.tempId
                )
            )
        } catch {
            updateFile({
                ...fileToDelete,
                error: 'Wystąpił błąd przy usuwaniu pliku.',
            })
        }
    }

    const submit = async (value) => {
        try {
            setSubmitError(null)

            const formData = formatSubmitData(value, clientSessionId, price)

            const { data } = await axiosInstance.post(
                ENDPOINTS.SUBMIT_FORM,
                formData
            )

            setTransactionPaymentUrl(data.transactionPaymentUrl)

            setActiveStep(STEPS[1])
        } catch {
            setSubmitError(
                'Coś poszło nie tak, nie udało się wysłać formularza.'
            )
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <WizardContext.Provider
            value={{
                activeStep,
                totalDuration,
                price,
                summaryExpanded,
                captchaSolved,
                uploadInProgress,
                analysisInProgress,
                stepError,
                unverifiedFiles,
                submitError,
                submitting,
                clientSessionId,
                setSubmitError,
                setStepError,
                setCaptchaSolved,
                setSummaryExpanded,
                setActiveStep,
                deleteFile,
                updateFile,
                submit,
            }}
        >
            <FormProvider {...methods}>{children}</FormProvider>
            {transactionPaymentUrl && (
                <PaymentOverlay transactionPaymentUrl={transactionPaymentUrl} />
            )}
        </WizardContext.Provider>
    )
}

export const useWizard = () => useContext(WizardContext)
